import { Dispatch, FunctionComponent, SetStateAction } from 'react';

export enum PaymentMode {
  ONE_TIME = 'ONE_TIME',
  UPSELL = 'UPSELL',
  SUBSCRIPTION = 'SUBSCRIPTION',
  SUBSCRIPTION_WITH_TRIAL = 'SUBSCRIPTION_WITH_TRIAL',
}

export interface IPlan {
  productId: string;
  solidId: string | null;
  pandaId: string;
  firstPayment: number;
  secondPayment: number;
  paymentMode: PaymentMode;
  multiplicator: number;
  currencySymbol: string;
  currencyCode: string;
  pdf: 'trauma' | 'pro' | 'career' | null;
  thankYouPage: 'subscription' | 'pdf';
}

export type IOfferInfo = Record<string, any>;
export type IPlanStatus = 'FULL_PLAN' | 'DISCOUNT_PLAN' | 'DOWNSELL_PLAN';

export type IConditionedPlans = Record<IPlanStatus, IPlan | null>;
export type ISelectedPlans = Record<string, IPlan>;
export type ICommonPlans = Record<string, IPlan>;

export interface ICheckoutDesignProps {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setupSolidCard: () => void;
  setupPaypal: () => void;
  setIsSmallLoader: Dispatch<SetStateAction<boolean>>;
  isDiscount: boolean;
  isDownsell: boolean;
  paymentError: string;
  plan: IPlan;
  userId: string;
  quizId: string;
  orderIntentId: string;
  isErrorNotification: boolean;
  setIsErrorNotification: Dispatch<SetStateAction<boolean>>;
  orderDescription: FunctionComponent<any>;
}

export interface IOrderDescription {
  plan: IPlan;
  isDiscount: boolean;
  isDownsell: boolean;
}
