import React, { FC, Suspense, useEffect, useState } from 'react';

import FullscreenLoader from '@common/components/elements/FullscreenLoader/FullscreenLoader';
import Route from '@common/components/helper/Route';
import '@common/styles/main.scss';
import {
  Route as BaseRoute,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';

import { FirebaseContext, IAbTest } from './context/FirebaseContext';
import { useUserId } from './hooks/useUserId';
import { analytics } from './services/analytics/Analytics';
import { panda } from './services/api/Panda';
import { getRemoteConfig } from './services/firebase/getRemoteConfig';
import { IConfig } from './types';
import { createPageViewsPayload } from './utils/createPageViewsPayload';
import { checkIsEmptyObject } from './utils/methods/checkIsEmptyObject';
import { hasOwnProperty } from './utils/methods/hasOwnProperty';

type Props = {
  config: IConfig;
};

const App: FC<Props> = ({
  config: { BASIC_CONFIG, ROUTER_CONFIG, FIREBASE_CONFIG },
}) => {
  const userId = useUserId();
  const [abTestValues, setAbTestValues] = useState<IAbTest>({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (userId) {
      analytics.setUserId(userId);
      analytics.setUserUTMs(queryParams, BASIC_CONFIG.quizId);
      analytics.setUserViewPortAndOs();

      const pageViews = createPageViewsPayload(location);

      const isFunnelWithoutABTests = checkIsEmptyObject(
        FIREBASE_CONFIG.defaultAbTest,
      );

      if (isFunnelWithoutABTests) {
        analytics.trackPageView({ location, userId });
        panda.updateUserPayload({ userId, payload: { pageViews } });
        return;
      }

      const asyncFetch = async (): Promise<void> => {
        const configData = await getRemoteConfig(FIREBASE_CONFIG.defaultAbTest);
        const abConfigPayload: Record<string, any> = {};

        Object.entries(configData).forEach(([key, value]) => {
          const shouldUseABTest = hasOwnProperty(
            FIREBASE_CONFIG.defaultAbTest,
            key,
          );

          if (shouldUseABTest) {
            abConfigPayload[`ab_${key}`] = value;
            analytics.sendABTestAsUserProperty(key, value);
          }
        });

        setAbTestValues(abConfigPayload);

        analytics.trackPageView({ location, userId });

        panda.updateUser({
          userId,
          payload: { pageViews, ...abConfigPayload },
        });
      };
      asyncFetch();
    }
  }, [userId]);

  return (
    <FirebaseContext.Provider value={abTestValues}>
      <Suspense fallback={<FullscreenLoader />}>
        <Switch>
          {ROUTER_CONFIG.pages.map((item) => (
            <Route
              key={item.path}
              title={item.title}
              path={item.path}
              exact={item.exact}
              Component={item.Component}
            />
          ))}

          <BaseRoute path="*">
            <Redirect to={ROUTER_CONFIG.defaultPage.path} />
          </BaseRoute>
        </Switch>
      </Suspense>
    </FirebaseContext.Provider>
  );
};

export default App;
