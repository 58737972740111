import React, { FC } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route as DefaultRoute, RouteProps } from 'react-router-dom';

type Props = RouteProps & {
  title: string;
  Component: FC;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Route: FC<Props> = ({ title, Component, ...props }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <DefaultRoute {...props}>
        <Component />
      </DefaultRoute>
    </HelmetProvider>
  );
};

export default Route;
