import { API_HOST } from '@common/config/constants';
import {
  customMerge,
  mergePayloadAnswers,
} from '@common/utils/methods/customMerge';
import mergeWith from 'lodash.mergewith';

interface IUpdateUserConfig {
  userId: string | null;
  payload: Record<string, any>;
  onbCompleted?: boolean;
}

class Panda {
  PANDA_SDK_TOKEN = '';

  init(pandaKey: string): void {
    this.PANDA_SDK_TOKEN = pandaKey;
  }

  async getUserById(userId: string): Promise<Record<string, any> | undefined> {
    if (!userId) return;

    try {
      const response = await fetch(`${API_HOST}/v1/users/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.PANDA_SDK_TOKEN,
        },
      });
      const data = response.json();
      return data;
    } catch (e) {
      console.error('Error while getUserById: ', e);
    }
  }

  async checkIsUserExist(userId: string): Promise<boolean | undefined> {
    if (!userId) {
      return;
    }

    try {
      const response = await fetch(`${API_HOST}/v1/users/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.PANDA_SDK_TOKEN,
        },
      });

      if (response?.status !== 200) {
        return;
      }

      const data = await response.json();
      return data.user_id;
    } catch (e) {
      console.error('Error while checkIsUserExist(): ', e);
    }
  }

  async createUser(): Promise<string> {
    try {
      const body = {
        user: {},
      };

      const response = await fetch(`${API_HOST}/v1/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.PANDA_SDK_TOKEN,
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      return data.id;
    } catch (e) {
      console.error('Error while createUser(): ', e);
      return '';
    }
  }

  async updateUser(
    config: IUpdateUserConfig,
  ): Promise<Record<string, any> | void> {
    const { userId, payload } = config;

    if (!userId) return;

    const user = await this.getUserById(userId);

    if (user) {
      const body = mergeWith(user, payload, customMerge);

      try {
        const response = await fetch(`${API_HOST}/v1/users/${userId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.PANDA_SDK_TOKEN,
          },
          body: JSON.stringify(body),
        });

        const data = await response.json();
        return data;
      } catch (e) {
        console.error('Error while updateUser(): ', e);
      }
    }
  }

  async updateUserPayload(
    config: IUpdateUserConfig,
  ): Promise<Record<string, any> | void> {
    const { userId, payload } = config;

    if (!userId) return;

    const user = await this.getUserById(userId);

    if (user) {
      const existingPayload = user?.payload && JSON.parse(user.payload);
      const existingId = user?.user_id;
      const newPayload =
        typeof existingPayload !== 'string'
          ? mergeWith(existingPayload, payload, customMerge)
          : payload;

      const body = {
        payload: JSON.stringify(newPayload),
      };

      try {
        const response = await fetch(`${API_HOST}/v1/users/${existingId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.PANDA_SDK_TOKEN,
          },
          body: JSON.stringify(body),
        });

        const data = await response.json();
        return data;
      } catch (e) {
        console.error('Error while updateUser(): ', e);
      }
    }
  }

  async updateUserIntents(
    config: IUpdateUserConfig,
  ): Promise<Record<string, any> | void> {
    const { userId, payload, onbCompleted } = config;

    if (!userId) return;

    const user = await this.getUserById(userId);

    if (user) {
      const existingPayload = user?.payload && JSON.parse(user.payload);
      const existingId = user?.user_id;

      const newPayload = mergePayloadAnswers(payload, existingPayload);

      const body = {
        payload: JSON.stringify(newPayload),
      };

      const endPoint = onbCompleted
        ? `${API_HOST}/v1/users/${existingId}?onb_completed=true`
        : `${API_HOST}/v1/users/${existingId}`;

      try {
        const response = await fetch(endPoint, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: this.PANDA_SDK_TOKEN,
          },
          body: JSON.stringify(body),
        });

        const data = await response.json();
        return data;
      } catch (e) {
        console.error('Error while updateUserPayload(): ', e);
      }
    }
  }

  async addEmailToUser(config: IUpdateUserConfig): Promise<any> {
    const { userId, payload } = config;
    const requestBody = {
      body: payload,
      user_id: userId,
    };

    try {
      const response = await fetch(`${API_HOST}/v1/email`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.PANDA_SDK_TOKEN,
        },
        method: 'POST',
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        return data;
      }
    } catch (error) {
      // errorLogger.captureException(
      //   response.status,
      //   'Error: addEmailToUser /v1/email',
      // );
    }
  }
}

export const panda = new Panda();
