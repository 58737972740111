import { IFirebaseConfig } from '@common/types';

const keys = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: 'sleep-booster.firebaseapp.com',
  databaseURL: 'https://sleep-booster.firebaseio.com',
  projectId: 'sleep-booster',
  storageBucket: 'sleep-booster.appspot.com',
  messagingSenderId: '923256167078',
  appId: '1:923256167078:web:f7c283ae25beab80497267',
  measurementId: 'G-EZKLCQYKL1',
};

export const SENSITIVE_COUNTRIES = 'sensitive_countries';

const defaultAbTest = {
  SENSITIVE_COUNTRIES: 'sensitive',
};

export const FIREBASE_CONFIG: IFirebaseConfig = {
  keys,
  defaultAbTest,
};
