import { createContext } from 'react';

// import { DEFAULT_AB_TEST_CONFIG } from 'config/firebase';

/* to use ab test:
1) set ab test in Firebase (using example there)
2) add new ab test param to DEFAULT_REMOTE_CONFIG in firebase.ts
3) add flow that should have ab test to FLOWS_AB_TEST in firebase.ts
4) use useAbTestValue.ts hook in that place where u need to check what u get from ab test
*/

export type IAbTest = Record<string, any>;

export const FirebaseContext = createContext<Partial<IAbTest>>({});
