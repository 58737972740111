import mergeWith from 'lodash.mergewith';

export const customMerge = (objVal: any, srcVal: any): any => {
  if (Array.isArray(objVal)) {
    return objVal.concat(srcVal);
  }
};

export const mergePayloadAnswers = (
  newPayload: Record<string, any>,
  existingPayload: Record<string, any>,
): Record<string, any> => {
  const orderIntentId = newPayload && newPayload.orderIntentId;

  const intents =
    existingPayload &&
    existingPayload.intents &&
    typeof existingPayload.intents === 'object'
      ? existingPayload.intents
      : {};

  const existingIntent = intents[orderIntentId] || {};
  const updatedIntent = mergeWith(existingIntent, newPayload, customMerge);

  const newIntents = {
    ...intents,
    [orderIntentId]: updatedIntent,
  };

  return { ...existingPayload, intents: newIntents };
};
