/**
 * Checks if prop is in obj and tells TypeScript about it
 *
 * @param obj - Object to check prop in
 * @param prop - Prop to check in object
 */
export const hasOwnProperty = <X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, any> => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
};
