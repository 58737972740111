import React, { FC } from 'react';

import Loader from '@common/components/elements/Loader/Loader';

import styles from './FullscreenLoader.module.scss';

const FullscreenLoader: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Loader />
    </div>
  );
};

export default FullscreenLoader;
