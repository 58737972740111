import { useEffect, useState } from 'react';

import { USER_ID } from '@common/config/constants';
import { panda } from '@common/services/api/Panda';
import { useLocation } from 'react-router-dom';

import useUserIdsFromRoute from './useUserIdsFromRoute';

export const useUserId = (): string | undefined => {
  const [userId, setUserId] = useState('');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { userIdFromRoute } = useUserIdsFromRoute();
  const userIdFromQuery = queryParams.get(USER_ID) ?? '';
  const userIdFromLocalStorage = localStorage.getItem(USER_ID) ?? '';

  useEffect(() => {
    if (userId) {
      return;
    }

    const isUserId = getUserId();

    if (!isUserId) {
      (async (): Promise<void> => {
        try {
          const newUserId = await panda.createUser();

          setUserToStores(newUserId);
        } catch (error) {
          console.error(error);
        }
      })();
      return;
    }

    (async (): Promise<void> => {
      try {
        const doesUserExist = await panda.checkIsUserExist(isUserId);

        if (!doesUserExist) {
          const newUserId = await panda.createUser();
          setUserToStores(newUserId);
          return;
        }

        setUserToStores(isUserId);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [userId]);

  const getUserId = (): string | undefined => {
    return userIdFromRoute || userIdFromQuery || userIdFromLocalStorage;
  };

  const setUserToStores = (id: string): void => {
    setUserId(id);
    localStorage.setItem(USER_ID, id);
  };

  if (userId) {
    return userId;
  }
};
