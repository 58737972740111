import { useRouteMatch } from 'react-router-dom';

interface IMatchParams {
  userId: string;
  orderIntentId: string;
}

const useUserIdsFromRoute = (): {
  userIdFromRoute: string;
  orderIntentIdFromRoute: string;
} => {
  const match = [
    useRouteMatch<IMatchParams>('/p/:userId/:orderIntentId'),
    useRouteMatch<IMatchParams>('/thank-you/:userId/:orderIntentId'),
    useRouteMatch<IMatchParams>('/em/:userId/:orderIntentId'),
    useRouteMatch<IMatchParams>('/exercise/:quizId/:userId/:orderIntentId'),
    useRouteMatch<IMatchParams>('/pre/:quizId/:userId/:orderIntentId'),
    useRouteMatch<IMatchParams>('/results/:quizId/:userId/:orderIntentId'),
    useRouteMatch<IMatchParams>('/results/sp/:quizId/:userId/:orderIntentId'),
  ];

  const currentRoute = match.find((route) => route !== null);
  if (!currentRoute) return { userIdFromRoute: '', orderIntentIdFromRoute: '' };

  const userIdFromRoute = currentRoute.params?.userId;
  const orderIntentIdFromRoute = currentRoute.params?.orderIntentId;

  return { userIdFromRoute, orderIntentIdFromRoute };
};

export default useUserIdsFromRoute;
