// Firebase App (the core Firebase SDK) is always required and must be listed first
import 'firebase/compat/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/performance';

// Initialize Firebase
const firebaseInit = (config): Record<string, any> => {
  let app = {};

  if (!firebase.apps.length) {
    app = firebase.initializeApp(config.keys);
  } else {
    app = firebase.app();
  }

  if (process.env.NODE_ENV !== 'test') {
    firebase.analytics();
    firebase.performance();
  }

  return app;
};

export default firebaseInit;
