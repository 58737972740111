export const USER_ID = 'userId';
export const QUIZ_ID = 'quizId';

// export const DEFAULT_PLAN = Plans.RS_WEB_USD_PDF_40;

export enum PAGES {
  FIRST_PAGE = 'FIRST_PAGE',
  QUIZ_PAGE = 'QUIZ_PAGE',
  LOADER_PAGE = 'LOADER_PAGE',
  EMAIL_PAGE = 'EMAIL_PAGE',
  SALES_PAGE = 'SALES_PAGE',
  THANK_YOU_PAGE = 'THANK_YOU_PAGE',
}

export const RS_EMAIL_ADDRESS = 'support@risespace.com';
export const RS_WEBSITE_DOMAIN_LINK = 'https://www.risespace.com';
export const RS_FUNNELS_DOMAIN_LINK = 'https://try.risespace.com';
export const RS_BLOG_DOMAIN_LINK = 'https://blog.risespace.com/';
export const RS_PLATFORM_DOMAIN_LINK = 'https://app.risespace.com/';

export const MANIFEST_DOMAIN_LINK = 'https://www.manifest-affirmations.com';

export const AVRORA_PROD_DOMAIN_LINK = 'https://avrora-app.com';
export const AVRORA_STAGE_DOMAIN_LINK = 'https://stage.avrora-app.com';

export const AVRORA_HEALTH_PROD_DOMAIN_LINK = 'https://health.avrora-app.com';
export const AVRORA_HEALTH_STAGE_DOMAIN_LINK =
  'https://health.avrora.stage-boosters.com/';

// export const PDF_PROCRASTINATION_DOWNLOAD_LINK =
//   'https://www.dropbox.com/s/f41z5vt40vprnx9/RS%20Pro.pdf?dl=0';

// export const PDF_TRAUMA_DOWNLOAD_LINK =
//   'https://www.dropbox.com/s/7ykt0jyykygu1gb/RS%20Trauma.pdf?dl=0';

export const API_HOST =
  process.env.NODE_ENV === 'production'
    ? 'https://api.panda.boosters.company'
    : '';

export const RS_API_PLATFORM_HOST =
  process.env.NODE_ENV === 'production'
    ? 'https://public-api.risespace.com'
    : 'https://public-api.v2.risespace.stage-boosters.com';

export const RS_TERMS_OF_USE = `${RS_WEBSITE_DOMAIN_LINK}/terms-and-conditions`;
export const RS_PRIVACY_POLICY = `${RS_WEBSITE_DOMAIN_LINK}/privacy-policy`;
export const RS_SUBSCRIPTION_TERMS = `${RS_WEBSITE_DOMAIN_LINK}/subscription-terms`;
export const RS_PLATFORM_RULES = `${RS_WEBSITE_DOMAIN_LINK}/platform-rules`;
export const RS_CRISIS_RESOURCES = `${RS_WEBSITE_DOMAIN_LINK}/crisis-resources`;
export const RS_CONTACT_US = `${RS_WEBSITE_DOMAIN_LINK}/contact-us`;

export const AVRORA_TERMS_OF_USE = `${AVRORA_PROD_DOMAIN_LINK}/terms-and-conditions`;
export const AVRORA_PRIVACY_POLICY = `${AVRORA_PROD_DOMAIN_LINK}/privacy-policy`;
export const AVRORA_SUBSCRIPTION_TERMS = `${AVRORA_PROD_DOMAIN_LINK}/subscription-terms`;
export const AVRORA_REFUND = `${AVRORA_PROD_DOMAIN_LINK}/refund`;
export const AVRORA_CONTACT_US = `${AVRORA_PROD_DOMAIN_LINK}/support`;
