import { IPlan } from '@common/types/payment';
import getPriceValue from '@common/utils/payment/getPriceValue';
import amplitude, { AmplitudeClient } from 'amplitude-js';
import UAParser from 'ua-parser-js';

interface IConfig {
  eventName: string;
  eventData?: any;
  userId?: string;
  email?: string;
}

interface IPaymentData {
  provider: string;
  [key: string]: any;
}

interface IPaymentConfig {
  eventName: string;
  total: IPlan;
  data: IPaymentData;
  pv?: number;
  email: string;
}

class Analytics {
  amplitudeAnalytics: AmplitudeClient;
  userId: string | null = null;
  AMPLITUDE_API_KEY: string | null = null;

  constructor() {
    this.amplitudeAnalytics = amplitude.getInstance();
  }

  private static getOSFull(): string {
    const parser = new UAParser();
    const UA = navigator.userAgent;
    parser.setUA(UA);

    const { name, version } = parser.getOS();

    return `${name} ${version}`;
  }

  init(amplitudeKey: string): void {
    this.AMPLITUDE_API_KEY = amplitudeKey;

    try {
      process.env.NODE_ENV !== 'production' &&
        // eslint-disable-next-line no-console
        console.log('Analytics is initiated');

      this.amplitudeAnalytics.init(this.AMPLITUDE_API_KEY);

      if (process.env.NODE_ENV !== 'production') return;
      const { gtag } = window as any;

      if (gtag) {
        gtag('set', 'init');
      }
    } catch (e) {
      console.error('Error while initiating analytics: ', e);
    }
  }

  // TODO! Should these methods be async?
  send(config: IConfig): void {
    try {
      const { eventName, eventData } = config;

      this.amplitudeAnalytics.logEvent(eventName, eventData);

      if (process.env.NODE_ENV !== 'production') return;

      const { gtag, fbq, AF, ttq } = window as any;

      if (fbq) {
        if (eventName === 'quizz_started') {
          fbq('track', 'ViewContent');
        }
        fbq('trackCustom', eventName, eventData);
      }

      if (ttq && eventName === 'quizz_started') {
        ttq.track('ClickButton');
      }

      if (gtag) {
        gtag('event', eventName, eventData);
      }

      if (AF) {
        AF('pba', 'event', {
          eventType: 'EVENT',
          eventCategory: 'customEvent',
          eventName,
          eventValue: eventData,
        });
      }
    } catch (e) {
      console.error('Error while sending send event in analytics: ', e);
    }
  }

  sendPayment(config: IPaymentConfig): void {
    try {
      const { eventName, data, total, pv, email } = config;

      const { productId } = total;
      const price = total.firstPayment / 100;
      const value = total ? getPriceValue(total, pv) : price;

      const revenue = new amplitude.Revenue()
        .setQuantity(1)
        .setPrice(price)
        .setRevenueType('purchase')
        .setProductId(productId)
        .setEventProperties({ currency: data.currency });
      amplitude.getInstance().logRevenueV2(revenue);
      amplitude.getInstance().logEvent(eventName, { ...data, amount: price });

      if (process.env.NODE_ENV !== 'production') return;

      const { gtag, fbq, AF, ttq, pintrk, dataLayer, obApi } = window as any;

      if (ttq && eventName === 'sales_payment_success') {
        ttq.identify({
          email,
        });
        ttq.track('CompletePayment');
      }

      if (gtag) {
        gtag('event', eventName, data);
        gtag('event', 'conversion', {
          send_to: `${process.env.GA_ID}/${process.env.GA_ID_PAYMENT_EVENT}`,
          value: price,
          currency: data.currency,
          transaction_id: productId,
        });
        gtag('event', 'purchase', {
          value,
          currency: data.currency,
          items: [
            {
              id: productId,
              name: productId,
              quantity: 1,
              price,
            },
          ],
        });
      }

      if (dataLayer) {
        dataLayer.push({
          ecommerce: {
            purchase: {
              actionField: {
                id: data.order_id, // Transaction ID. Required for purchases and refunds.
                revenue: price,
                currency: data.currency,
              },
              products: [
                {
                  // List of productFieldObjects.
                  name: productId,
                  id: productId,
                  price,
                  quantity: 1,
                },
              ],
            },
          },
        });
      }

      if (obApi) {
        obApi('track', 'Purchase');
      }

      if (fbq) {
        fbq('trackCustom', eventName, data);
        fbq('track', 'Purchase', {
          value,
          currency: data.currency,
          content_ids: [productId],
          content_type: 'product',
        });
      }

      if (AF) {
        AF('pba', 'event', {
          eventType: 'EVENT',
          eventCategory: 'Purchase',
          eventName,
          eventRevenue: value,
          eventRevenueCurrency: data.currency,
          eventValue: data,
        });
      }

      if (pintrk) {
        pintrk('track', 'checkout', {
          value,
          order_quantity: 1,
          currency: data.currency,
          line_items: [
            {
              product_name: productId,
              product_id: productId,
            },
          ],
        });
      }
    } catch (error) {
      console.error(
        'Error while sending sendPayment event in analytics: ',
        error,
      );
    }
  }

  sendUserEmail(config: IConfig): void {
    try {
      const { eventName, eventData } = config;

      const identify = new amplitude.Identify().add('email', eventData.email);
      amplitude.getInstance().identify(identify); // send the Identify call
      amplitude.getInstance().logEvent(eventName, eventData);

      if (process.env.NODE_ENV !== 'production') return;
      const { AF, gtag, pintrk, fbq, ttq } = window as any;

      if (gtag) {
        gtag('event', eventName, eventData);
        gtag('event', 'sign_up', eventData);
        gtag('event', 'conversion', {
          // TODO! check
          send_to: `${process.env.GA_ID}/${process.env.GA_ID_EMAIL_EVENT}`,
        });
      }

      if (ttq && eventName === 'onb_email_completed') {
        ttq.identify({
          email: eventData.email,
        });
        ttq.track('CompleteRegistration');
      }

      if (pintrk) {
        pintrk('load', process.env.PINTRK_ID, {
          em: eventData.email,
        });
        pintrk('track', 'signup', eventData);
      }

      if (fbq) {
        fbq('init', process.env.FBQ_ID, {
          em: eventData.email,
        });
        fbq('trackCustom', eventName, eventData);
        fbq('track', 'CompleteRegistration', eventData);
      }

      if (AF) {
        AF('pba', 'event', {
          eventType: 'EVENT',
          eventCategory: 'registrationCompleted',
          eventName,
          eventValue: eventData,
        });
      }
    } catch (error) {
      console.error(
        'Error while sending sendUserEmail event in analytics: ',
        error,
      );
    }
    // mapEventsToOutbrain(config);
  }

  setUserId(userId: string): void {
    this.amplitudeAnalytics.setUserId(userId);
    window.hj &&
      window.hj('identify', userId, {
        userId,
      });
  }

  setUserProperties(props: any): void {
    this.amplitudeAnalytics.setUserProperties(props);
  }

  setUserUTMs(queryParams: URLSearchParams, funnelName: string): void {
    if (process.env.NODE_ENV !== 'production') return;

    const params = {
      funnel_name: funnelName,
      campaign_id: queryParams.get('utm_campaign'),
      ad_id: queryParams.get('utm_content'),
      source: queryParams.get('utm_source'),
      medium: queryParams.get('utm_medium'),
      term: queryParams.get('utm_term'),
      ad_name: queryParams.get('utm_ad_name'),
      campaign_name: queryParams.get('utm_campaign_name'),
      adset_name: queryParams.get('utm_adset_name'),
      adset_id: queryParams.get('utm_adset_id'),
      placement: queryParams.get('utm_placement'),
    };

    this.amplitudeAnalytics.setUserProperties(params);
  }

  setUserViewPortAndOs(): void {
    const os = Analytics.getOSFull();

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const userProperties = {
      screen_size: `${windowWidth}x${windowHeight}`,
      os,
    };

    this.setUserProperties(userProperties);
  }

  trackPageView(config: Record<string, any>): void {
    try {
      const { location, userId = '' } = config;
      const queryObject: Record<string, any> = {};

      this.amplitudeAnalytics.setUserId(userId);
      this.amplitudeAnalytics.setUserProperties(queryObject);
      this.amplitudeAnalytics.logEvent('page_view', {
        page_path: location.pathname + location.search,
      });

      if (process.env.NODE_ENV !== 'production') return;

      const { gtag, fbq, pintrk, ttq, twq } = window as any;

      if (gtag) {
        gtag('config', {
          user_id: userId,
        });
        gtag('config', {
          page_path: location.pathname + location.search,
        });
      }

      if (ttq) {
        ttq.track('ViewContent');
      }

      if (twq) {
        twq('track', 'PageView');
      }

      if (fbq) {
        fbq('track', 'PageView');
      }

      if (pintrk) {
        pintrk('track', 'pagevisit');
      }
    } catch (error) {
      console.error(
        'Error while sending trackPageView event in analytics: ',
        error,
      );
    }
  }

  initiateCheckout(config: IConfig): void {
    const { eventName, eventData, email } = config;

    amplitude.getInstance().logEvent(eventName, eventData);

    if (process.env.NODE_ENV !== 'production') return;

    const { gtag, fbq, AF, ttq, pintrk, dataLayer, obApi, twttr, lintrk } =
      window as any;

    if (eventName === 'sale_checkout' && ttq && email) {
      ttq.identify({
        email,
      });
      ttq.track('InitiateCheckout');
    }

    if (eventName === 'sale_checkout') {
      const flow = localStorage.getItem('quizId');

      if (flow === 'career') {
        // if (twq) {
        //   twq('event', 'tw-o9mt5-obp61', {});
        // }

        if (twttr) {
          twttr.conversion.trackPid('obp61', {
            tw_sale_amount: 0,
            tw_order_quantity: 0,
          });
        }

        if (lintrk) {
          lintrk('track', { conversion_id: 9269794 });
        }
      }
    }

    if (gtag) {
      gtag('event', eventName, eventData);
      gtag('event', 'begin_checkout', eventData);
      gtag('event', 'conversion', {
        send_to: 'AW-695606285/GU2MCOCym8ACEI242MsC',
      });
    }

    if (dataLayer) {
      dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: eventData.provider },
            products: [
              {
                name: eventData.productId,
                id: eventData.productId,
                price: eventData.amiunt,
                quantity: 1,
              },
            ],
          },
        },
      });
    }

    if (obApi) {
      obApi('track', 'Checkout');
    }

    if (fbq) {
      fbq('trackCustom', eventName, eventData);
      fbq('track', 'InitiateCheckout', eventData);
    }

    if (AF) {
      AF('pba', 'event', {
        eventType: 'EVENT',
        eventCategory: 'SubmitApplication',
        eventName,
        eventValue: eventData,
      });
    }

    if (pintrk) {
      pintrk('track', 'initiatecheckout', eventData);
    }
  }

  submitApplication(config: IConfig): void {
    const { eventName, eventData } = config;

    amplitude.getInstance().logEvent(eventName, eventData);

    if (process.env.NODE_ENV !== 'production') return;

    const { gtag, fbq, AF } = window as any;

    if (gtag) {
      gtag('event', eventName, eventData);
      gtag('event', 'conversion', {
        send_to: 'AW-695606285/hHDpCLD7wcACEI242MsC',
      });
    }

    if (fbq) {
      fbq('trackCustom', eventName, eventData);
      fbq('track', 'SubmitApplication', eventData);
    }

    if (AF) {
      AF('pba', 'event', {
        eventType: 'EVENT',
        eventCategory: 'SubmitApplication',
        eventName,
        eventValue: eventData,
      });
    }
  }

  sendABTestAsUserProperty(key: string, value: any): void {
    this.amplitudeAnalytics.setUserProperties({
      'ab test': {
        [key]: value,
      },
    });
  }
}

export const analytics = new Analytics();
