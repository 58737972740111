import {
  AVRORA_CONTACT_US,
  AVRORA_HEALTH_PROD_DOMAIN_LINK,
  AVRORA_HEALTH_STAGE_DOMAIN_LINK,
  AVRORA_PRIVACY_POLICY,
  AVRORA_SUBSCRIPTION_TERMS,
  AVRORA_TERMS_OF_USE,
} from '@common/config/constants';
import { IBasicConfig, IFooterLink } from '@common/types';

const quizId = 'a-health';

const footerLinks: IFooterLink[] = [
  { id: 'terms', link: AVRORA_TERMS_OF_USE, title: 'Terms of Servise' },
  { id: 'privacy', link: AVRORA_PRIVACY_POLICY, title: 'Privacy Policy' },
  {
    id: 'subscription',
    link: AVRORA_SUBSCRIPTION_TERMS,
    title: 'Subscription Terms',
  },
  { id: 'contacts', link: AVRORA_CONTACT_US, title: 'Contact Us' },
];

export const BASIC_CONFIG: IBasicConfig = {
  quizId,
  footerLinks,
  domainLinks: {
    STAGE_DOMAIN_LINK: AVRORA_HEALTH_STAGE_DOMAIN_LINK,
    PROD_DOMAIN_LINK: AVRORA_HEALTH_PROD_DOMAIN_LINK,
  },
};
