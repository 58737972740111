import { lazy } from 'react';

import AbstractRouterConfig from '@common/AbstractRouterConfig';
import { PAGES } from '@common/config/constants';
import {
  IPageItemConfigType,
  IRouteItemType,
  IRouterConfig,
} from '@common/types';

const QuizPage = lazy(() => import('../pages/QuizPage/QuizPage'));
const LoaderPage = lazy(() => import('../pages/LoaderPage/LoaderPage'));
const EmailPage = lazy(() => import('../pages/EmailPage/EmailPage'));
const SalesPage = lazy(() => import('../pages/SalesPage/SalesPage'));
const ThankYouPage = lazy(() => import('../pages/ThankYouPage/ThankYouPage'));

const TITLE = `Improve Your Sleep`;

export const PageConfig: Record<string, IPageItemConfigType> = {
  [PAGES.QUIZ_PAGE]: {
    Component: QuizPage,
    path: '/quiz',
    title: TITLE,
  },
  [PAGES.LOADER_PAGE]: {
    Component: LoaderPage,
    path: '/loader',
    title: TITLE,
  },
  [PAGES.EMAIL_PAGE]: {
    Component: EmailPage,
    path: '/em',
    title: TITLE,
  },
  [PAGES.SALES_PAGE]: {
    Component: SalesPage,
    path: '/p',
    title: TITLE,
  },
  [PAGES.THANK_YOU_PAGE]: {
    Component: ThankYouPage,
    path: '/thank-you',
    title: TITLE,
  },
};

const pages: IRouteItemType[] = [
  {
    exact: true,
    path: PageConfig.QUIZ_PAGE.path,
    Component: PageConfig.QUIZ_PAGE.Component,
    title: PageConfig.QUIZ_PAGE.title,
  },
  {
    exact: true,
    path: `${PageConfig.LOADER_PAGE.path}/:userId/:orderIntentId`,
    Component: PageConfig.LOADER_PAGE.Component,
    title: PageConfig.LOADER_PAGE.title,
  },
  {
    exact: true,
    path: `${PageConfig.EMAIL_PAGE.path}/:userId/:orderIntentId`,
    Component: PageConfig.EMAIL_PAGE.Component,
    title: PageConfig.EMAIL_PAGE.title,
  },
  {
    exact: true,
    path: `${PageConfig.SALES_PAGE.path}/:userId/:orderIntentId`,
    Component: PageConfig.SALES_PAGE.Component,
    title: PageConfig.SALES_PAGE.title,
  },
  {
    exact: true,
    path: `${PageConfig.THANK_YOU_PAGE.path}/:userId/:orderIntentId`,
    Component: PageConfig.THANK_YOU_PAGE.Component,
    title: PageConfig.THANK_YOU_PAGE.title,
  },
];

class RouterConfig extends AbstractRouterConfig {
  pages = pages;
  defaultPage = pages[0];
}

export const ROUTER_CONFIG: IRouterConfig = new RouterConfig();
