import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';

export const getRemoteConfig = async (
  defaultAbTest: Record<string, string>,
): Promise<Record<string, any>> => {
  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 320;
  remoteConfig.defaultConfig = defaultAbTest;

  const configData = await remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getAll();
    })
    .catch(() => {
      return defaultAbTest;
    });

  return configData;
};
