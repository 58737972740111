import { IPlan, PaymentMode } from '@common/types/payment';

// const MULT_DEFAULT = 2.25;
const PV_DEFAULT = 0.53;

const getSubscriptionTrialValue = (
  firstPayment: number,
  secondPayment: number,
  multiplicator: number,
  pv: number | undefined,
): number => {
  const value =
    Math.round(
      (firstPayment + secondPayment) * multiplicator * (pv ?? PV_DEFAULT),
    ) / 100;

  return value;
};

const getSubscriptionValue = (
  firstPayment: number,
  multiplicator: number,
): number => {
  const value = Math.round(firstPayment * multiplicator) / 100;

  return value;
};

const getOneTimeValue = (firstPayment: number): number => {
  return firstPayment / 100;
};

const getPriceValue = (total: IPlan, pv?: number): number => {
  const { firstPayment, secondPayment, paymentMode, multiplicator } = total;

  switch (paymentMode) {
    case PaymentMode.ONE_TIME:
      return getOneTimeValue(firstPayment);

    case PaymentMode.SUBSCRIPTION:
      return getSubscriptionValue(firstPayment, multiplicator);

    case PaymentMode.SUBSCRIPTION_WITH_TRIAL:
      return getSubscriptionTrialValue(
        firstPayment,
        secondPayment,
        multiplicator,
        pv,
      );

    default:
      return 0;
  }
};

export default getPriceValue;
