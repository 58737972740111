import { IConfig } from '@common/types';

import { BASIC_CONFIG } from './basicConfig';
import { FIREBASE_CONFIG } from './firebaseConfig';
import { ROUTER_CONFIG } from './routerConfig';

const CONFIG: IConfig = {
  FIREBASE_CONFIG,
  ROUTER_CONFIG,
  BASIC_CONFIG,
};

export default CONFIG;
