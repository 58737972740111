import { hasOwnProperty } from './hasOwnProperty';

export const checkIsEmptyObject = (obj: Object): boolean => {
  for (const i in obj) {
    if (hasOwnProperty(obj, i)) {
      return false;
    }
  }
  return true;
};
