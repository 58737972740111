import React from 'react';

import App from '@common/App';
import { analytics } from '@common/services/analytics/Analytics';
import { panda } from '@common/services/api/Panda';
import firebaseInit from '@common/services/firebase/firebase';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import CONFIG from './config';

if (process.env.AMPLITUDE_API_KEY) {
  analytics.init(process.env.AMPLITUDE_API_KEY);
}

if (process.env.FIREBASE_API_KEY) {
  firebaseInit(CONFIG.FIREBASE_CONFIG);
}

if (process.env.PANDA_SDK_TOKEN) {
  panda.init(process.env.PANDA_SDK_TOKEN);
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App config={CONFIG} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
