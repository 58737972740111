export const createPageViewsPayload = (
  location: any,
): {
  timestamp: number;
  url: any;
}[] => {
  return [
    {
      timestamp: new Date().getTime(),
      url: location.pathname + location.search,
    },
  ];
};
